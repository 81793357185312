import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the aggregation fields of Canned Messages aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Date`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the interaction in YYYY-MM-DD format. For example, `}<inlineCode parentName="td">{`2025-03-19`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of interaction (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      